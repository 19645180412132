import React from "react";
import { Helmet } from "react-helmet";

function Seo({
  title = "India's 1st AI-driven Influencer Marketing Platform, CPM-based!",
  description = "Nothing matter more than ROI, right? We got you. No upfront costs. ZERO commissions. Guaranteed ROI. Only pay for what you get.",
  imgUrl = "https://www.anchors.in:5000/api/file/anchors-logo.jpeg",
  keywords = "Join Anchors for creators, influencer marketing India,free LinkedIn analyzer,Creator economy platform,Collaborate with creators,Best platform for content creators in India,how to make money from LinkedIn India,Get Brand Collaboration,Brand collaboration India,Collab Portfolio website,Content Creator portfolio,How to become Brand ambassador,How to Showcase my brand collaboration,LinkedIn Influencer platform",
}) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <link
        rel="canonical"
        href={`https://www.anchors.in${window.location.pathname}`}
      />
      <meta name="keywords" content={keywords} />

      {/* <!-- Facebook Meta Tags --> */}
      <meta
        property="og:url"
        content={`https://www.anchors.in${window.location.pathname}`}
      />
      <meta property="og:site_name" content="anchors" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imgUrl} />
      <meta property="og:type" content="product" />
      <meta property="og:locale" content="en_US" />

      {/* <!-- Twitter Meta Tags --> */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="anchors.in" />
      <meta
        property="twitter:url"
        content={`https://www.anchors.in${window.location.pathname}`}
      />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imgUrl} />
    </Helmet>
  );
}

export default Seo;
